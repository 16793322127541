import { ReactNode } from 'react';
import useAuth from '../hooks/useAuth';
import PermissionErrorPage from '../pages/PermissionErrorPage';

type PermissionGuardProps = {
  children: ReactNode;
  permission: string;
};

export default function PermissionGuard({ children, permission }: PermissionGuardProps) {
  const { user } = useAuth();

  if (!user?.permissions[permission]?.read) {
    return <PermissionErrorPage/>;
  }

  return <>{children}</>;
}
