import { createSlice } from '@reduxjs/toolkit';
// @types
import { options } from '../../../@types/categories/categories';
import { CoursesCategory, CoursesCategoryManager } from '../../../@types/coursesCategory/coursesCategory';
import { useDispatch, } from '../../store'
import { get, post, put} from '../api/api'
import { isString } from 'lodash';
import { dispatch } from '../../store';
import { CustomFile } from 'src/components/upload';

// ----------------------------------------------------------------------

type CoursesCategoryListState = {
  isLoading: boolean,
  error: Error | string | null;
  CoursesCategoryToEdit: CoursesCategoryManager | null,
};

const initialState: CoursesCategoryListState = {
  isLoading: true,
  error: null,
  CoursesCategoryToEdit: null,
};

const slice = createSlice({
  name: 'CoursesCategory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCoursesCategoryToEditSuccess(state,action){
      state.isLoading = false;
      state.CoursesCategoryToEdit = action.payload;
    },

    resetCoursesCategoryToEdit(state){
      state.CoursesCategoryToEdit = null;
    }
  },
});

// Reducer
export default slice.reducer;

export const { resetCoursesCategoryToEdit } = slice.actions;

export function registerCoursesCategory( newCourseCategory : any ){
  return async () => {
    try{
      var response = await get('/continuousEducation/all');
      var coursesCategoryList: CoursesCategory[] = [];
      response.data.forEach((tab: any) =>{
        if(tab.hasCategories){
          tab.continuousEducationCategory.forEach((category: any) =>{
            coursesCategoryList.push(category);
          })
        }
      })
      var continuousEducationTab: any = response.data[0];
      if(!isString(newCourseCategory.image)){
        var image64 = await toBase64(newCourseCategory.image);
        var imagem_url = await put('/files/uploadImage',{path: newCourseCategory.image.path, base64: image64});
        newCourseCategory.image = imagem_url.data;
      }else{
        newCourseCategory.image = newCourseCategory.image;
      }
      coursesCategoryList.push(newCourseCategory);

      continuousEducationTab.continuousEducationCategory = coursesCategoryList;

      await put('/continuousEducation/', continuousEducationTab);
    }catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function getCoursesCategoryById(id: string) {
  return async () => {
    try {
      var response = await get('/continuousEducation/all')
      var courseCategoryToEdit: any = []
      response.data.forEach((tab: any) =>{
        if(tab.hasCategories){
          tab.continuousEducationCategory.forEach((category: any) =>{
            if(category._id === id){
              courseCategoryToEdit = category;
            }
          })
        }
      })
      if(!courseCategoryToEdit._id){
        return 'error'
      }else{
        dispatch(slice.actions.getCoursesCategoryToEditSuccess(courseCategoryToEdit));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editCoursesCategory( updatedCourseCategory: any ){
  return async () => {
    try{
      var response = await get('/continuousEducation/all');
      var coursesCategoryList: CoursesCategory[] = [];
      response.data.forEach((tab: any) =>{
        if(tab.hasCategories){
          tab.continuousEducationCategory.forEach((category: any) =>{
            coursesCategoryList.push(category);
          })
        }
      })
      var continuousEducationTab: any = response.data[0];
      if(!isString(updatedCourseCategory.image)){
        var image64 = await toBase64(updatedCourseCategory.image);
        var imagem_url = await put('/files/uploadImage',{path: updatedCourseCategory.image.path, base64: image64});
        updatedCourseCategory.image = imagem_url.data;
      }else{
        updatedCourseCategory.image = updatedCourseCategory.image;
      }
      coursesCategoryList.forEach((category: any) => {
        if(category._id === updatedCourseCategory._id){
          category.image = updatedCourseCategory.image;
          category.title = updatedCourseCategory.title;
          category.order = updatedCourseCategory.order;
        }
      })
      continuousEducationTab.continuousEducationCategory = coursesCategoryList;
      await put('/continuousEducation/', continuousEducationTab);
    }catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}


const toBase64 = (file: CustomFile) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export function deleteCoursesCategory(deletedCourseCategoryId: string){
  return async () =>{
    try{
      var response = await get('/continuousEducation/all');
      var coursesCategoryList: CoursesCategory[] = [];
      response.data.forEach((tab: any) =>{
        if(tab.hasCategories){
          tab.continuousEducationCategory.forEach((category: any) =>{
            coursesCategoryList.push(category);
          })
        }
      })
      var continuousEducationTab: any = response.data[0];
      coursesCategoryList.forEach((category: any) => {
        if(category._id === deletedCourseCategoryId){
          category.status = 'inactive';
        }
      })

      continuousEducationTab.continuousEducationCategory = coursesCategoryList;

      await put('/continuousEducation/', continuousEducationTab);

    }catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}