import { createSlice } from '@reduxjs/toolkit';
import { UserManager } from '../../../@types/user/user';
import { UserAuthPermissions } from '../../../@types/auth';
import { get, post } from '../api/api'
import { dispatch } from '../../store';
type UserDetailsState = {
  isLoading: boolean,
  error: Error | string | null;
  userToEdit: UserManager | null,
  userList: UserManager[]
};

const initialState: UserDetailsState = {
  isLoading: true,
  error: null,
  userToEdit: null,
  userList: []
};

const slice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getUserToEditSuccess(state, action) {
      state.isLoading = false;
      state.userToEdit = action.payload;
    },
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    resetUserToEdit(state) {
      state.userToEdit = null;
    },
  },
});

// Reducer
export default slice.reducer;

export const { resetUserToEdit } = slice.actions;

export function getUserToEdit(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const user = await get('user/' + id)
      if (user.status === 500) {
        return 'error'
      } else {
        console.log(user)
        dispatch(slice.actions.getUserToEditSuccess(user.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserList() {
  return async () => {
    try {
      var response = await get('user/all')
      var users = response.data.filter((user: any) => user.admin && user.status === 'active')
      dispatch(slice.actions.getUserListSuccess(users))
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerUser(user: UserManager, permissions: UserAuthPermissions) {
  return async () => {
    try {
      user.admin = true;
      user.adminInfo = { permissions };
      user.cadastroCompleto = true;
      user.cpf = user.cpf.replace(/[^\d]+/g, '');
      user.celular = user.celular.replace(/[^\d]+/g, '');
      var response = await post('auth/register', user)
      if (response.status === 400) {
        if (response.data.message.includes('Email')) {
          return 'snackbar.emailAlreadyRegistered'
        } else {
          return 'snackbar.cpfAlreadyRegistered'
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUser(user: UserManager, permissions: UserAuthPermissions) {
  return async () => {
    try {
      user.admin = true;
      user.adminInfo = { permissions };
      user.cadastroCompleto = true;
      user.cpf = user.cpf.replace(/[^\d]+/g, '');
      user.celular = user.celular.replace(/[^\d]+/g, '');
      var response = await post('user/update', user)
      if (response.status === 400) {
        if (response.data.message.includes('Email')) {
          return 'snackbar.emailAlreadyRegistered'
        } else {
          return 'snackbar.cpfAlreadyRegistered'
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteUser(id: string) {
  return async () => {
    try {
      await post('user/deactivate/'+ id, {})
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
} 