import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
import useLocales from '../hooks/useLocales'

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function PermissionErrorPage() {
  const { translate } = useLocales() 

  return (
    <Page title={translate('permissionErrorPage.title')} sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                {translate('permissionErrorPage.messageTitle')}
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
                {translate('permissionErrorPage.message')}
            </Typography>
            <Button to="/" size="large" variant="contained" component={RouterLink} sx={{mt:2}}>
              {translate('permissionErrorPage.button')}
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
