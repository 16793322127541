import { createSlice } from '@reduxjs/toolkit';
// @types
import { options } from '../../../@types/categories/categories';
import { FamilyActivityManager } from '../../../@types/familyActivity/familyActivity';
import { useDispatch, } from '../../store'
import { get, post, put} from '../api/api'
import { isString } from 'lodash';
import { dispatch } from '../../store';
import { CustomFile } from 'src/components/upload';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  familyActivityToEdit: FamilyActivityManager | null
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  familyActivityToEdit: null,
};

const slice = createSlice({
  name: 'familyActivity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetFamilyActivityToEdit(state){
        state.familyActivityToEdit = null;
    },

    getFamilyActivityToEditSuccess(state, action){
      state.familyActivityToEdit = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

export const { resetFamilyActivityToEdit } = slice.actions;

export function createOrUpdateFamilyActivity(values: FamilyActivityManager, id?: string) {
    return async () => {
      try {
        var content: any = {}
        if(id){
            content._id = id;
        }
        content.title = values.title;
        content.description = values.description;
        content.order = values.order;

        if(!isString(values.image)){
            var image64 = await toBase64(values.image)
            var imagem_url = await put('/files/uploadImage',{path: values.image.path, base64: image64})
            content.image = imagem_url.data;
        }else{
            content.image = values.image;
        }
        if(values.link && values.link !== ''){
            content.contentUrl = values.link;
        }else{
            if(!isString(values.archive)){
                var document64 = await toBase64(values.archive)
                var document_url = await post('/files/',{file:{path: values.archive.path, base64: document64}})
                content.contentUrl = document_url.data;
            }else{
                content.contentUrl = values.archive;
            }
        }

        await put('/familyActivities/', content)
      } catch (error) {
        throw new Error(error.message)
      }
    };
  }

export function getFamilyActivityToEdit(id: string){
  return async () => {
    try{
      var familyActivity = await get('/familyActivities/byId/'+id)
      if(familyActivity.status === 500){
        return 'error'
      }else{
        dispatch(slice.actions.getFamilyActivityToEditSuccess(familyActivity.data))
      }
    }catch (error){
      throw new Error(error.message)
    }
  }
}

export function deleteFamilyActivity(id: string){
  return async () =>{
    try{
      await put('/familyActivities/', {_id: id, status: 'inactive'})
    }catch (error){
      throw new Error(error.message)
    }
  }
}


const toBase64 = (file: CustomFile) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});