import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import apiReducer from './slices/api/api'
import planningDetailsReducer from './slices/planning/planningDetails';
import planningListReducer from './slices/planning/planningList'
import userDetailsReducer from './slices/user/userDetails'
import activityDetailsReducer from './slices/activity/activityDetails';
import loadindReducer from './slices/loading/loading'
import ageGroupReducer from './slices/categories/ageGroup'
import ageGroupGroupReducer from './slices/categories/ageGroupGroup'
import experienceFieldReducer from './slices/categories/experienceField'
import knowledgeReducer from './slices/categories/knowledge'
import pedagogicCategoryReducer from './slices/categories/pedagogicCategory'
import teacherDetailsReducer from './slices/teacher/teacherDetails'
import continuousEducationDetailsReducer from './slices/continuousEducation/continuousEducationDetails'
import continuousEducationListReducer from './slices/continuousEducation/continuousEducationList'
import familyActivityDetailsReducer from './slices/familyActivity/familyActivityDetails';
import familyActivityListReducer from './slices/familyActivity/familyActivityList';
import postReducer from './slices/post/postList';
import logReducer from './slices/logs/logs';
import learningReducer from './slices/categories/learning'
import coursesCategoryListReducer from './slices/coursesCategory/coursesCategoryList';
import coursesCategoryDetailsReducer from './slices/coursesCategory/coursesCategoryDetails';
import cityReducer from './slices/city/city'

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  api: apiReducer,
  userDetails: userDetailsReducer,
  planningDetails: planningDetailsReducer,
  planningList: planningListReducer,
  activityDetails: activityDetailsReducer,
  loading: loadindReducer,
  ageGroup: ageGroupReducer,
  ageGroupGroup: ageGroupGroupReducer,
  experienceField: experienceFieldReducer,
  knowledge: knowledgeReducer,
  learning: learningReducer,
  pedagogicCategory: pedagogicCategoryReducer,
  teacherDetails: teacherDetailsReducer,
  continuousEducationDetails: continuousEducationDetailsReducer,
  continuousEducationList: continuousEducationListReducer,
  familyActivityDetails: familyActivityDetailsReducer,
  familyActivityList: familyActivityListReducer,
  postList: postReducer,
  logs: logReducer,
  coursesCategoryList: coursesCategoryListReducer,
  coursesCategoryDetails: coursesCategoryDetailsReducer,
  city: cityReducer
});

export { rootPersistConfig, rootReducer };
