import { createSlice } from '@reduxjs/toolkit';
// @types
import { options } from '../../../@types/categories/categories';
import { useDispatch, } from '../../store'
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  pedagogicCategoriesOptions: options[],
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  pedagogicCategoriesOptions: [],
};

const slice = createSlice({
  name: 'pedagogicCategorie',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPedagogicCategoriesOptionsSuccess(state, action){
      state.isLoading = false;
      state.pedagogicCategoriesOptions = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

export function getPedagogicCategoriesOptions() {
  return async () => {
    try {
      var pedagogicCategories = await get('/options/all?options=pedagogicCategories')
      var options: options[] = [];
      pedagogicCategories.data.pedagogicCategories.forEach((pedagogicCategorie: any) =>{
        if(pedagogicCategorie.status === 'active'){
          options.push({value: pedagogicCategorie._id, label: pedagogicCategorie.descricao, order: pedagogicCategorie.order})
        }
      })
      dispatch(slice.actions.getPedagogicCategoriesOptionsSuccess(options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createOrUpdatePedagogicCategory(pedagogicCategory: {_id?: string, descricao?: string, order?: number, status?: string}) {
  return async () => {
    try {
      await put('/options/pedagogicCategory', pedagogicCategory)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}