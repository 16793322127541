import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------
import { dispatch } from '../../store';
import { get } from '../api/api'
import { city } from '../../../@types/city/city';

type LoadingState = {
  isLoading: boolean,
  error: Error | string | null;
  cities: city[],
};

const initialState: LoadingState = {
  isLoading: false,
  error: null,
  cities: []
};

const slice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
        state.isLoading = true;
    },

    stopLoading(state) {
        state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCitiesSuccess(state, action){
        state.isLoading = false;
        state.cities = action.payload;
    }
    
  },
});

export const { startLoading, stopLoading } = slice.actions;

// Reducer
export default slice.reducer;


export function getCities() {
    return async () => {
      try {
        var response = await get('/cities/getAll')
        dispatch(slice.actions.getCitiesSuccess(response.data))
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }