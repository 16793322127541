import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import AuthGuard from '../guards/AuthGuard';
import ActivityValuesGuard from '../guards/ActivityValuesGuard';
import PlanningValuesGuard from '../guards/PlanningValuesGuard';
import PermissionGuard from '../guards/PermissionGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/initial" replace />,
    },
    {
      path: '/dashboard',
      element: <AuthGuard><DashboardLayout /></AuthGuard>,
      children: [
        { element: <Navigate to="/dashboard/initial" replace />, index: true },
        { path: 'initial', element: <PageOne /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <PermissionGuard permission='userList'><UserList /></ PermissionGuard> },
            { path: 'register', element: <PermissionGuard permission='userCreate'><UserDetails /></PermissionGuard> },
            { path: ':userId/edit', element: <PermissionGuard permission='userEdit'><UserDetails /></PermissionGuard> },
          ],
        },
        {
          path: 'planning',
          children: [
            { element: <Navigate to="/dashboard/planning/list" replace />, index: true },
            { path: 'list', element: <PermissionGuard permission='planningList'><PlanningList /></ PermissionGuard> },
            { path: 'register', element: <PermissionGuard permission='planningCreate'><PlanningDetails /></PermissionGuard> },
            { path: 'categories', element: <PermissionGuard permission='categories'><Categories /></PermissionGuard> },
            { path: ':planningId/edit', element: <PermissionGuard permission='planningEdit'><PlanningDetails /></PermissionGuard> },
          ],
        },
        {
          path: 'activity',
          children: [
            { element: <Navigate to="/dashboard/activity/register" replace />, index: true },
            { path: 'register', element: <PlanningValuesGuard><ActivityDetails /></PlanningValuesGuard> },
            { path: 'edit', element: <ActivityValuesGuard><ActivityDetails /></ActivityValuesGuard> },
          ],
        },
        {
          path: 'teacher',
          children: [
            { element: <Navigate to="/dashboard/teacher/list" replace />, index: true },
            { path: 'list', element: <PermissionGuard permission='teacherList'><TeacherList /></ PermissionGuard> },
            { path: 'register', element: <PermissionGuard permission='teacherCreate'><TeacherDetails /></PermissionGuard> },
            { path: ':teacherId/edit', element: <PermissionGuard permission='teacherEdit'><TeacherDetails /></PermissionGuard> },
          ],
        },
        {
          path: 'continuous-education',
          children: [
            { element: <Navigate to="/dashboard/continuous-education/list" replace />, index: true },
            { path: 'list', element: <PermissionGuard permission='continuousEducationList'><ContinuousEducationList /></ PermissionGuard> },
            { path: 'register', element: <PermissionGuard permission='continuousEducationCreate'><ContinuousEducationDetails /></PermissionGuard> },
            { path: ':continuousEducationId/edit', element: <PermissionGuard permission='continuousEducationEdit'><ContinuousEducationDetails /></PermissionGuard> },
          ],
        },
        {
          path: 'courses-category',
          children: [
            { element: <Navigate to="/dashboard/courses-category/list" replace />, index: true },
            { path: 'list', element: <PermissionGuard permission='coursesCategoryList'><CoursesCategoryList /></ PermissionGuard> },
            { path: 'register', element: <PermissionGuard permission='coursesCategoryCreate'><CoursesCategoryDetails /></PermissionGuard> },
            { path: ':coursesCategoryId/edit', element: <PermissionGuard permission='coursesCategoryEdit'><CoursesCategoryDetails /></PermissionGuard> },
          ],
        },
        {
          path: 'family-activity',
          children: [
            { element: <Navigate to="/dashboard/family-activity/list" replace />, index: true },
            { path: 'list', element: <PermissionGuard permission='familyActivityList'><FamilyActivityList /></ PermissionGuard> },
            { path: 'register', element: <PermissionGuard permission='familyActivityCreate'><FamilyActivityDetails /></PermissionGuard> },
            { path: ':familyActivityId/edit', element: <PermissionGuard permission='familyActivityEdit'><FamilyActivityDetails /></PermissionGuard> },
          ],
        },
        {
          path: 'post',
          children: [
            { element: <Navigate to="/dashboard/postList/list" replace />, index: true },
            { path: 'list', element: <PermissionGuard permission='postList'><PostList /></ PermissionGuard> },
          ],
        },
        {
          path: 'logs',
          element: <PermissionGuard permission='logs'><LogsList /></ PermissionGuard>
        },
        {
          path: 'logsApp',
          element: <PermissionGuard permission='logsApp'><LogsAppList /></ PermissionGuard>
        },
      ],
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '/login', element: <Login /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const UserDetails = Loadable(lazy(() => import('../pages/userDetails/UserDetails')));
const UserList = Loadable(lazy(() => import('../pages/userList/UserList')));
const PlanningList = Loadable(lazy(() => import('../pages/planningList/PlanningList')))
const PlanningDetails = Loadable(lazy(() => import('../pages/planningDetails/PlanningDetails')));
const ActivityDetails = Loadable(lazy(() => import('../pages/activityDetails/ActivityDetails')));
const Categories = Loadable(lazy(() => import('../pages/categories/Categories')));
const TeacherDetails = Loadable(lazy(() => import('../pages/teacherDetails/TeacherDetails')));
const TeacherList = Loadable(lazy(() => import('../pages/teacherList/TeacherList')));
const ContinuousEducationDetails = Loadable(lazy(() => import('../pages/continuousEducationDetails/ContinuousEducationDetails')));
const ContinuousEducationList = Loadable(lazy(() => import('../pages/continuousEducationList/ContinuousEducationList')));
const CoursesCategoryDetails = Loadable(lazy(() => import('../pages/coursesCategoryDetails/CoursesCategoryDetails')));
const CoursesCategoryList = Loadable(lazy(() => import('../pages/coursesCategoryList/CoursesCategoryList')));
const FamilyActivityDetails = Loadable(lazy(() => import('../pages/familyActivityDetails/FamilyActivityDetails')));
const FamilyActivityList = Loadable(lazy(() => import('../pages/familyActivityList/FamilyActivityList')));
const PostList = Loadable(lazy(() => import('../pages/postList/pages/PostList')));
const LogsList = Loadable(lazy(() => import('../pages/logsList/LogsList')));
const LogsAppList = Loadable(lazy(() => import('../pages/logsAppList/LogsList')));
const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/privacyPolicy/PrivacyPolicyPage')));