import './_apis_';

// scroll bar
import 'simplebar/src/simplebar.css';

import './utils/highlight';

import 'react-quill/dist/quill.snow.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';


import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { store, persistor } from './redux/store';
// contexts
import { AuthProvider } from './contexts/JWTContext';
// import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import './locales/i18n';

//
import App from './App';

// ----------------------------------------------------------------------

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <HelmetProvider>
            {/* <SettingsProvider> */}
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CollapseDrawerProvider>
            {/* </SettingsProvider> */}
        </HelmetProvider>
      </AuthProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);
