// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    create: path(ROOTS_DASHBOARD, '/user/register'),
    edit: path(ROOTS_DASHBOARD, '/user/:userId/edit'),
  },
  planning: {
    root: path(ROOTS_DASHBOARD, '/planning'),
    list: path(ROOTS_DASHBOARD, '/planning/list'),
    create: path(ROOTS_DASHBOARD, '/planning/register'),
    categories: path(ROOTS_DASHBOARD, '/planning/categories'),
    edit: path(ROOTS_DASHBOARD, '/planning/:planningId/edit'),
  },
  teacher:{
    root: path(ROOTS_DASHBOARD, '/teacher'),
    list: path(ROOTS_DASHBOARD, '/teacher/list'),
    create: path(ROOTS_DASHBOARD, '/teacher/register'),
    edit: path(ROOTS_DASHBOARD, '/teacher/:teacherId/edit'),
  },
  activity:{
    create: path(ROOTS_DASHBOARD, '/activity/register'),
    edit: path(ROOTS_DASHBOARD, '/activity/edit'),
  },
  continuousEducation:{
    root: path(ROOTS_DASHBOARD, '/continuous-education'),
    list: path(ROOTS_DASHBOARD, '/continuous-education/list'),
    create: path(ROOTS_DASHBOARD, '/continuous-education/register'),
    edit: path(ROOTS_DASHBOARD, '/continuous-education/:continuousEducationId/edit'),
  },
  coursesCategory:{
    root: path(ROOTS_DASHBOARD, '/courses-category'),
    list: path(ROOTS_DASHBOARD, '/courses-category/list'),
    create: path(ROOTS_DASHBOARD, '/courses-category/register'),
    edit: path(ROOTS_DASHBOARD, '/courses-category/:courseCategoryId/edit'),
  },
  familyActivity:{
    root: path(ROOTS_DASHBOARD, '/family-activity'),
    list: path(ROOTS_DASHBOARD, '/family-activity/list'),
    create: path(ROOTS_DASHBOARD, '/family-activity/register'),
    edit: path(ROOTS_DASHBOARD, '/family-activity/:familyActivityId/edit'),
  },
  post:{
    root: path(ROOTS_DASHBOARD, '/post'),
    list: path(ROOTS_DASHBOARD, '/post/list'),
  },
};
