import { createSlice } from '@reduxjs/toolkit';
// @types
import { useDispatch, } from '../../store'
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  learningOptions: {value: string, label: string, knowledge: string}[],
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  learningOptions: [],
};

const slice = createSlice({
  name: 'learning',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getLearnigsOptionsSuccess(state, action){
      state.isLoading = false;
      state.learningOptions = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

export function getLearningsOptions(ageGroupGroupId: string) {
  return async () => {
    try {
      var learnings = await get('/attributes/learning/getAll?ageGroupGroupId='+ageGroupGroupId)
      var options: {value: string, label: string, knowledge: string}[] = [];
      learnings.data.forEach((learning: any) =>{
        if(learning.status === 'active'){
          options.push({value: learning._id, label: learning.title, knowledge: learning.knowledge})
        }
      })
      options.sort(function(a, b){
        if(a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
        if(a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
        return 0;
      })
      dispatch(slice.actions.getLearnigsOptionsSuccess(options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createOrUpdateLearning(learning: {_id?: string, title?: string, knowledge?: string, status?: string, ageGroupGroups?: string[]}) {
  return async () => {
    try {
      await put('/options/learning', learning)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}