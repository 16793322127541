import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from '../redux/store';

// ----------------------------------------------------------------------

type ActivityValuesGuardProps = {
  children: ReactNode;
};

export default function ActivityValuesGuard({ children }: ActivityValuesGuardProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { activityValues } = useSelector((state) => state.activityDetails)

  if (!activityValues) {
    enqueueSnackbar('Erro',{variant:'error'});
    return <Navigate to={'/dashboard'} />
  }

  return <>{children}</>;
}
