import { createSlice } from '@reduxjs/toolkit';
// @types
import { options } from '../../../@types/categories/categories';
import { useDispatch, } from '../../store'
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  knowledgesOptions: options[],
  knowledges: {value: string, label: string, experienceField?: string, learnings: options[]}[],
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  knowledgesOptions: [],
  knowledges: [],
};

const slice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getKnowledgesOptionsSuccess(state, action){
      state.isLoading = false;
      state.knowledgesOptions = action.payload;
    },

    getKnowledgesSuccess(state, action){
      state.isLoading = false;
      state.knowledges = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

export function getKnowledgesOptions(ageGroupGroupId: string) {
  return async () => {
    try {
      var knowledges = await get('/attributes/knowledge/getAll?ageGroupGroupId='+ageGroupGroupId)
      // var options: options[] = [];
      var options: { value: string; label: string; order?: number | undefined; experienceField?: string}[] = []
      knowledges.data.forEach((knowledge: any) =>{
        if(knowledge.status === 'active'){
          options.push({value: knowledge._id, label: knowledge.title, experienceField: knowledge.experienceField ? knowledge.experienceField : '' })
        }
      })
      options.sort(function(a, b){
        if(a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
        if(a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
        return 0;
      })
      dispatch(slice.actions.getKnowledgesOptionsSuccess(options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKnowledges() {
  return async () => {
    try {
      var knowledges = await get('/options/all?options=knowledges')
      var options: {value: string, label: string, experienceField: string, learnings: {value: string, label: string, selected: boolean}[]}[] = [];
      var map = knowledges.data.knowledges.map(async(knowledge: any) =>{
        if(knowledge.status === 'active'){
          var allLearnings = await get('/attributes/learning/getAll?knowledge='+knowledge._id)
          var learnings: {value: string, label: string, selected: boolean}[] = []
          allLearnings.data.forEach((learning: any) =>{
            learnings.push({value: learning._id, label: learning.title, selected: false})
          })
          learnings.sort(function(a, b){
            if(a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
            if(a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
            return 0;
          })
          options.push({value: knowledge._id, label: knowledge.title, experienceField: knowledge.experienceField ? knowledge.experienceField : '', learnings: learnings})
        }
      })
      await Promise.all(map)
      options.sort(function(a, b){
        if(a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
        if(a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
        return 0;
      })
      dispatch(slice.actions.getKnowledgesSuccess(options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createOrUpdateKnowledge(knowledge: {_id?: string, title?: string, experienceField?: string, status?: string, ageGroupGroups?: string[]}) {
  return async () => {
    try {
      await put('/options/knowledge', knowledge)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}