import { createSlice } from '@reduxjs/toolkit';
import { UserManager } from '../../../@types/user/user';
import { get, post, put } from '../api/api'
import { dispatch } from '../../store';
import { isString } from 'lodash';
import { CustomFile } from 'src/components/upload';

type TeacherDetailsState = {
  isLoading: boolean,
  error: Error | string | null;
  teacherToEdit: UserManager | null,
  teacherList: UserManager[]
};

const initialState: TeacherDetailsState = {
  isLoading: false,
  error: null,
  teacherToEdit: null,
  teacherList: []
};

const slice = createSlice({
  name: 'teacherDetails',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTeacherToEditSuccess(state, action) {
      state.isLoading = false;
      state.teacherToEdit = action.payload;
    },
    getTeacherListSuccess(state, action) {
      state.isLoading = false;
      state.teacherList = action.payload;
    },
    resetTeacherToEdit(state) {
      state.teacherToEdit = null;
    },
  },
});

export default slice.reducer;

export const { resetTeacherToEdit } = slice.actions;

export function getTeacherToEdit(id: string) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const user = await get('user/' + id)
      if (user.status === 500) {
        return 'error'
      } else {
        dispatch(slice.actions.getTeacherToEditSuccess(user.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTeacherList() {
  return async () => {
    try {
      dispatch(slice.actions.startLoading())
      var response = await get('user/all')
      var users = response.data.filter((user: any) => !user.admin && user.status === 'active')
      dispatch(slice.actions.getTeacherListSuccess(users))
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerTeacher(user: UserManager) {
  return async () => {
    try {
      if (user.imageUrl === '') {
        delete user.imageUrl
      }
      if (user.imageUrl && !isString(user.imageUrl)) {
        var imagem64 = await toBase64(user.imageUrl)
        var imagem_url = await put('/files/uploadImage', { path: user.imageUrl.path, base64: imagem64 })
        user.imageUrl = imagem_url.data;
      }
      user.admin = false;
      user.cadastroCompleto = false;
      user.cpf = user.cpf.replace(/[^\d]+/g, '');
      // user.celular = user.celular.replace(/[^\d]+/g, '');
      var response = await post('auth/register', user)
      if (response.status === 400) {
        if (response.data.message.includes('Email')) {
          return 'snackbar.emailAlreadyRegistered'
        } else {
          return 'snackbar.cpfAlreadyRegistered'
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerManyTeachers(teachers: any) {
  return async () => {
    try {
      var response = await post('auth/registerMany', { teachers })
      return response
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTeacher(user: UserManager) {
  return async () => {
    try {
      if (user.imageUrl === '') {
        delete user.imageUrl
      }
      if (user.imageUrl && !isString(user.imageUrl)) {
        var imagem64 = await toBase64(user.imageUrl)
        var imagem_url = await put('/files/uploadImage', { path: user.imageUrl.path, base64: imagem64 })
        user.imageUrl = imagem_url.data;
      }
      user.admin = false;
      // user.cadastroCompleto = false;
      user.cpf = user.cpf.replace(/[^\d]+/g, '');
      // user.celular = user.celular.replace(/[^\d]+/g, '');
      var response = await post('user/update', user)
      if (response.status === 400) {
        if (response.data.message.includes('Email')) {
          return 'snackbar.emailAlreadyRegistered'
        } else {
          return 'snackbar.cpfAlreadyRegistered'
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

const toBase64 = (file: CustomFile) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export function deleteTeacher(id: string) {
  return async () => {
    try {
      await post('user/deactivate/'+ id, {})
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
} 