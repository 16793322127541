import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
// import { api } from '../config';
import { api } from '../config'
import { isValidToken, setSession } from '../utils/jwt';
import { useDispatch } from '../redux/store';
import { get, post }  from '../redux/slices/api/api'
import { PERMISSIONS_FALSE } from '../_mock/permission'
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType, UserAuthPermissions } from '../@types/auth';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const dispatcher = useDispatch()
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
        const accessToken = window.localStorage.getItem('@impare-portal-accessToken');
        const refreshToken = window.localStorage.getItem('@impare-portal-refreshToken');

        if(accessToken && refreshToken){
          setSession(accessToken, refreshToken);

          const response = await get('user/')
          if(response.status === 200){
            var permissions = {...PERMISSIONS_FALSE};
            Object.keys(PERMISSIONS_FALSE).forEach((key) => {
              if(!response.data.adminInfo.permissions[key as keyof UserAuthPermissions]){
                permissions[key as keyof UserAuthPermissions] = PERMISSIONS_FALSE[key as keyof UserAuthPermissions]
              }else{
                permissions[key as keyof UserAuthPermissions] = response.data.adminInfo.permissions[key as keyof UserAuthPermissions]
              }
            })
            var user ={...response.data, permissions: permissions}
  
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user,
              },
            });

          }else{
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    var emBase64 = btoa(`${email}:${password}`)
    api.defaults.headers.common.Authorization = `Basic ${emBase64}`
    const res = await get('auth/loginAdmin')

    if(res.status === 200){
      const { accessToken, refreshToken } = res.data;
      if(accessToken && refreshToken){
        setSession(accessToken, refreshToken);
        var response = await get('user/')
      }
      if(response.status === 200){
        var permissions = {...PERMISSIONS_FALSE};
          Object.keys(PERMISSIONS_FALSE).forEach((key) => {
            if(!response.data.adminInfo.permissions[key as keyof UserAuthPermissions]){
              permissions[key as keyof UserAuthPermissions] = PERMISSIONS_FALSE[key as keyof UserAuthPermissions]
            }else{
              permissions[key as keyof UserAuthPermissions] = response.data.adminInfo.permissions[key as keyof UserAuthPermissions]
            }
          })
        var user ={...response.data, permissions: permissions}
        dispatch({
          type: Types.Login,
          payload: {
            user,
          },
        });
      }else{
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return res
      }

    }else{
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return res
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await api.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('@impare-portal-accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    await post('auth/logout',{})
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
