import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';
import Image from './Image';
import { LOGO_PATH, APP_NAME } from '../config'


// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  hasName?: boolean;
  isCollapse?: boolean;
}

export default function Logo({ isCollapse, disabledLink = false, hasName = false, sx }: Props) {
  const theme = useTheme()

  const logo = (
    <Stack direction='row' alignItems='center' spacing={2}>
      <Box sx={{ width: isCollapse ? 40 : 100, height: 40, ...sx }}>
        <Image disabledEffect src={process.env.PUBLIC_URL + LOGO_PATH} alt='LOGO'/>
      </Box>
      {hasName &&
        <Typography variant="subtitle1">
          {APP_NAME}
        </Typography>
      }
    </Stack>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/" style={{textDecoration:'none', color: theme.palette.text.primary}}>{logo}</RouterLink>;
}
