import { createSlice } from '@reduxjs/toolkit';
// @types
import { options } from '../../../@types/categories/categories';
import { useDispatch, } from '../../store'
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  experienceFieldsOptions: options[],
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  experienceFieldsOptions: [],
};

const slice = createSlice({
  name: 'experienceField',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getExperienceFieldsOptionsSuccess(state, action){
      state.isLoading = false;
      state.experienceFieldsOptions = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

export function getExperienceFieldsOptions(ageGroupGroupId: string) {
  return async () => {
    try {
      var experienceFields = await get('/attributes/experienceField/getAll?ageGroupGroupId='+ageGroupGroupId)
      var options: options[] = [];
      experienceFields.data.forEach((experince: any) =>{
        if(experince.status === 'active'){
          options.push({value: experince._id, label: experince.title})
        }
      })
      options.sort(function(a, b){
        if(a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
        if(a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
        return 0;
      })
      dispatch(slice.actions.getExperienceFieldsOptionsSuccess(options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createOrUpdateExperienceField(experienceField: {_id?: string, title?: string, status?: string, ageGroupGroups?: string[]}) {
  return async () => {
    try {
      await put('/options/experienceField', experienceField)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}