import { UserAuthPermissions } from "src/@types/auth"

  export const PERMISSIONS_FALSE: UserAuthPermissions = {
    planning: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    planningList: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    planningCreate: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    planningEdit: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    categories: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    user: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    userList: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    userCreate: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    userEdit: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    teacher: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    teacherCreate:{
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    teacherEdit:{
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    teacherList:{
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    continuousEducation: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    continuousEducationList: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    continuousEducationCreate: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    continuousEducationEdit: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    coursesCategory: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    coursesCategoryList: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    coursesCategoryCreate: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    coursesCategoryEdit: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    familyActivity: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    familyActivityList: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    familyActivityCreate: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    familyActivityEdit: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    post: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    postList: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }, 
    postCreate: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    postEdit: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    logs: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },  
    logsApp: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }
  }

  export const PERMISSIONS_CREATE: UserAuthPermissions = {
    planning: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    planningList: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    planningCreate: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    planningEdit: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    categories: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    user: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    userList: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    userCreate: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    userEdit: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    teacher: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    teacherCreate:{
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    teacherEdit:{
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    teacherList:{
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    continuousEducation: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    continuousEducationList: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    continuousEducationCreate: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    continuousEducationEdit: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    coursesCategory: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    coursesCategoryList: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    coursesCategoryCreate: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    coursesCategoryEdit: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    familyActivity: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    familyActivityList: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    familyActivityCreate: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    familyActivityEdit: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    post: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    postList: {
      create: false,
      read: true,
      update: false,
      delete: false,
    }, 
    postCreate: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    postEdit: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    logs: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },   
    logsApp: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }
  }