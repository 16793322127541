import { createSlice } from '@reduxjs/toolkit';
// @types
import { ContinuousEducation } from '../../../@types/continuousEducation/continuousEducation';
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';
import moment from 'moment';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  continuousEducationList: ContinuousEducation[],
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  continuousEducationList: []
};

const slice = createSlice({
  name: 'continuousEducationList',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getContinuousEducationListSuccess(state, action){
      state.isLoading = false;
      state.continuousEducationList = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

export function getContinuousEducationList(){
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      var response = await get('/continuousEducation/all')
      var list: ContinuousEducation[] = []
      response.data.forEach((tab: any) =>{
        if(tab.hasCategories){
            tab.continuousEducationCategory.forEach((category: any) =>{
                category.continuousEducationContent.forEach((content: any) =>{
                    list.push({
                        _id: content._id,
                        image: content.image,
                        title: content.title,
                        order: content.order?? 1,
                        type: content.contentType === 'link' ? 'Link' : 'Arquivo',
                        categorie: category.title,
                        createdAt: tab.createdAt
                    })
                })
            })
        }else{
            tab.continuousEducationContent.forEach((content: any) =>{
                list.push({
                    _id: content._id,
                    image: content.image,
                    title: content.title,
                    order: content.order?? 1,
                    type: content.contentType === 'link' ? 'Link' : 'Arquivo',
                    categorie: tab.title,
                    createdAt: tab.createdAt
                })
            })
        }
      })
      list.sort(function(a: any, b: any){
        if(moment(a.order) < moment(b.order)) { return 1; }
        if(moment(a.order) > moment(b.order)) { return -1; }
        return 0;
      })
      dispatch(slice.actions.getContinuousEducationListSuccess(list))
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
 };
}