import { createSlice } from '@reduxjs/toolkit';
// @types
import { FamilyActivity } from '../../../@types/familyActivity/familyActivity';
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';
import moment from 'moment';
import { OrderCompleteIllustration } from 'src/assets';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  familyActivityList: FamilyActivity[],
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  familyActivityList: []
};

const slice = createSlice({
  name: 'continuousEducationList',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFamilyActivityListSuccess(state, action){
      state.isLoading = false;
      state.familyActivityList = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

export function getFamilyActivityList(){
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      var response = await get('/familyActivities/all')
      var list: FamilyActivity[] = []
      // response.data.sort(function(a: any, b: any){
      //   if(moment(a.order) > moment(b.order)) { return 1; }
      //   if(moment(a.order) < moment(b.order)) { return -1; }
      //   return 0;
      // })
      response.data.forEach((data: any) =>{
        if(data.status === 'active'){
          list.push({
              _id: data._id,
              image: data.image,
              title: data.title,
              order: data.order?? 1,
              contentType: data.contentUrl.includes('storage.googleapis.com') ? 'Arquivo' : 'Link',
          })
        }
      })
      dispatch(slice.actions.getFamilyActivityListSuccess(list))
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
 };
}