import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  book: getIcon('ic_book'),
  config: getIcon('ic_config'),
  family: getIcon('ic_family'),
  teacher: getIcon('ic_teacher'),
  posts: getIcon('ic_post')
};

const navConfig = [
  {
    subheader: "dashboard.sidebar.general.title",
    items: [
      { 
        title: 'dashboard.sidebar.general.children.planning.title', 
        path: PATH_DASHBOARD.planning.root, 
        icon: ICONS.analytics, 
        auth: 'planning', 
        children: [
          { title: 'dashboard.sidebar.general.children.planning.children.list', path: PATH_DASHBOARD.planning.list, auth: 'planningList' },
          { title: 'dashboard.sidebar.general.children.planning.children.create', path: PATH_DASHBOARD.planning.create, auth: 'planningCreate' },
          { title: 'dashboard.sidebar.general.children.planning.children.categories', path: PATH_DASHBOARD.planning.categories, auth: 'categories' },
          { title: 'dashboard.sidebar.general.children.planning.children.edit', path: PATH_DASHBOARD.planning.edit, showWhenPathInclude: ['planning','edit'], auth: 'planningEdit' },
          { title: 'dashboard.sidebar.general.children.planning.children.edit', path: PATH_DASHBOARD.activity.edit, showWhenPathInclude: ['activity','edit']},
          { title: 'dashboard.sidebar.general.children.planning.children.create', path: PATH_DASHBOARD.activity.create, showWhenPathInclude: ['activity','register'] },
        ],
      },
      { 
        title: 'dashboard.sidebar.general.children.continuousEducation.title', 
        path: PATH_DASHBOARD.continuousEducation.root, 
        icon: ICONS.book, 
        auth: 'continuousEducation', 
        children: [
          { title: 'dashboard.sidebar.general.children.continuousEducation.children.list', path: PATH_DASHBOARD.continuousEducation.list, auth: 'continuousEducationList' },
          { title: 'dashboard.sidebar.general.children.continuousEducation.children.create', path: PATH_DASHBOARD.continuousEducation.create, auth: 'continuousEducationCreate' },
          { title: 'dashboard.sidebar.general.children.continuousEducation.children.edit', path: PATH_DASHBOARD.continuousEducation.edit, showWhenPathInclude: ['continuous-education','edit'], auth: 'continuousEducationEdit' },
        ],
      },
      { 
        title: 'dashboard.sidebar.general.children.coursesCategory.title', 
        path: PATH_DASHBOARD.coursesCategory.root, 
        icon: ICONS.book, 
        auth: 'coursesCategory', 
        children: [
          { title: 'dashboard.sidebar.general.children.coursesCategory.children.list', path: PATH_DASHBOARD.coursesCategory.list, auth: 'coursesCategoryList' },
          { title: 'dashboard.sidebar.general.children.coursesCategory.children.create', path: PATH_DASHBOARD.coursesCategory.create, auth: 'coursesCategoryCreate' },
          { title: 'dashboard.sidebar.general.children.coursesCategory.children.edit', path: PATH_DASHBOARD.coursesCategory.edit, showWhenPathInclude: ['courses-category','edit'], auth: 'coursesCategoryEdit' },
        ],
      },
      { 
        title: 'dashboard.sidebar.general.children.familyActivity.title', 
        path: PATH_DASHBOARD.familyActivity.root, 
        icon: ICONS.family, 
        auth: 'familyActivity', 
        children: [
          { title: 'dashboard.sidebar.general.children.familyActivity.children.list', path: PATH_DASHBOARD.familyActivity.list, auth: 'familyActivityList' },
          { title: 'dashboard.sidebar.general.children.familyActivity.children.create', path: PATH_DASHBOARD.familyActivity.create, auth: 'familyActivityCreate' },
          { title: 'dashboard.sidebar.general.children.familyActivity.children.edit', path: PATH_DASHBOARD.familyActivity.edit, showWhenPathInclude: ['family-activity','edit'], auth: 'familyActivityEdit' },
        ],
      },
      { 
        title: 'dashboard.sidebar.general.children.post.title', 
        path: PATH_DASHBOARD.post.root, 
        icon: ICONS.posts, 
        auth: 'post', 
        children: [
          { title: 'dashboard.sidebar.general.children.post.children.list', path: PATH_DASHBOARD.post.list, auth: 'postList' },
        ],
      },
    ],
  },
  {
    subheader: 'dashboard.sidebar.management.title',
    items: [
      {
        title: 'dashboard.sidebar.management.children.user.title',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        auth: 'user',
        children: [
          { title: 'dashboard.sidebar.management.children.user.children.list', path: PATH_DASHBOARD.user.list, auth: 'userList' },
          { title: 'dashboard.sidebar.management.children.user.children.create', path: PATH_DASHBOARD.user.create, auth: 'userCreate' },
          { title: 'dashboard.sidebar.management.children.user.children.edit', path: PATH_DASHBOARD.user.edit, showWhenPathInclude: ['user','edit'], auth: 'userEdit' },
        ],
      },
      {
        title: 'dashboard.sidebar.management.children.teacher.title',
        path: PATH_DASHBOARD.teacher.root,
        icon: ICONS.teacher,
        auth: 'teacher',
        children: [
          { title: 'dashboard.sidebar.management.children.teacher.children.list', path: PATH_DASHBOARD.teacher.list, auth: 'teacherList' },
          { title: 'dashboard.sidebar.management.children.teacher.children.create', path: PATH_DASHBOARD.teacher.create, auth: 'teacherCreate' },
          { title: 'dashboard.sidebar.management.children.teacher.children.edit', path: PATH_DASHBOARD.teacher.edit, showWhenPathInclude: ['teacher','edit'], auth: 'teacherEdit' },
        ],
      },
    ],
  },
  {
    subheader: 'dashboard.sidebar.configuration.title',
    items: [
      {
        title: 'dashboard.sidebar.configuration.children.logs',
        path: '/dashboard/logs',
        icon: ICONS.config,
        auth: 'logs',
      },
      {
        title: 'Logs de Acesso do App',
        path: '/dashboard/logsApp',
        icon: ICONS.config,
        auth: 'logsApp',
      },
    ],
  },
];

export default navConfig;
