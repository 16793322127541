import { createSlice } from '@reduxjs/toolkit';
// @types
import { Planning } from '../../../@types/planning/planning';
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  planningList: Planning[],
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  planningList: []
};

const slice = createSlice({
  name: 'planningList',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPlanningListSuccess(state, action){
      state.isLoading = false;
      state.planningList = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

export function getPlanningList(){
  return async () => {
    try {
      var response = await get('planejamento/all')
      dispatch(slice.actions.getPlanningListSuccess(response.data))
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
 };
}