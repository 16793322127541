import { createSlice } from '@reduxjs/toolkit';
// @types
import { CoursesCategory } from '../../../@types/coursesCategory/coursesCategory';
import { get, post, put} from '../api/api'

import { dispatch } from '../../store';
import moment from 'moment';

// ----------------------------------------------------------------------

type CoursesCategoryListState = {
  isLoading: boolean,
  error: Error | string | null;
  coursesCategoryList: CoursesCategory[],
};

const initialState: CoursesCategoryListState = {
  isLoading: true,
  error: null,
  coursesCategoryList: []
};

const slice = createSlice({
  name: 'CoursesCategoryList',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCoursesCategoryListSuccess(state, action){
      state.isLoading = false;
      state.coursesCategoryList = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

export function getCoursesCategoryList(){
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      var response = await get('/continuousEducation/all')
      var list: CoursesCategory[] = []
      response.data.forEach((tab: any) =>{
        if(tab.hasCategories){
          tab.continuousEducationCategory.forEach((category: any) =>{
            if(category.status === 'active' || category.status === undefined){
              list.push({
                  _id: category._id,
                  image: category.image,
                  title: category.title,
                  status: category.status?? "active",
                  order: category.order?? 1,
                  createdAt: tab.createdAt
              })
            }
          })
        }
      })
      // list.sort(function(a: any, b: any){
      //   if(moment(a.order) > moment(b.order)) { return -1; }
      //   if(moment(a.order) < moment(b.order)) { return 1; }
      //   return 0;
      // })
      dispatch(slice.actions.getCoursesCategoryListSuccess(list))
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
 };
}