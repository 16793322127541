import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

type LoadingState = {
  isLoading: boolean,
  error: Error | string | null;
};

const initialState: LoadingState = {
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
        state.isLoading = true;
    },

    stopLoading(state) {
        state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
  },
});

export const { startLoading, stopLoading } = slice.actions;

// Reducer
export default slice.reducer;