import { createSlice } from '@reduxjs/toolkit';
import { PostType } from '../../../@types/post/post';
import { get, deletee } from '../api/api';
import { dispatch } from '../../store';

type PostListState = {
  isLoading: boolean,
  error: Error | string | null;
  postList: Array<PostType>,
};

const initialState: PostListState = {
  isLoading: true,
  error: null,
  postList: []
};

const slice = createSlice({
  name: 'postList',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPostListSuccess(state, action) {
      state.isLoading = false;
      state.postList = action.payload;
    }
  },
});

export default slice.reducer;

export function getPostList() {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await get('/post/painel');
      dispatch(slice.actions.getPostListSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePost(id: string) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await deletee(`/post/${id}`);
      dispatch(slice.actions.getPostListSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}