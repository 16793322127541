import { createSlice } from '@reduxjs/toolkit';
import { log, logApp } from '../../../@types/logs/logs';
import { get, put } from '../api/api'
import { dispatch } from '../../store';
import moment from 'moment'

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null,
  logsList: log[],
  logsAppList: logApp[]
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  logsList: [],
  logsAppList: [],
};

const slice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getLogsListSuccess(state, action) {
      state.logsList = action.payload;
    },
    getLogsAppListSuccess(state, action) {
      state.logsAppList = action.payload;
    }
  },
});

export default slice.reducer;

export function getLogsList() {
  return async () => {
    try {
      var response = await get('/log/all')
      response.data.forEach((log: any) => {
        log.user = log.user.nome;
        log.date = moment(log.date).format('DD/MM/YYYY - HH:mm')
      })
      dispatch(slice.actions.getLogsListSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLogsAppList() {
  return async () => {
    try {
      var response = await get('/analytics/getAllPainel')
      dispatch(slice.actions.getLogsAppListSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createOrUpdateLog(body: log) {
  return async () => {
    try {
      await put('/log/', body)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}