import { createSlice } from '@reduxjs/toolkit';
// @types
import { options } from '../../../@types/categories/categories';
import { categorieOption, ContinuousEducationManager } from '../../../@types/continuousEducation/continuousEducation';
import { useDispatch, } from '../../store'
import { get, post, put} from '../api/api'
import { isString } from 'lodash';
import { dispatch } from '../../store';
import { CustomFile } from 'src/components/upload';

// ----------------------------------------------------------------------

type PlanningListState = {
  isLoading: boolean,
  error: Error | string | null;
  continuousEducationOptions: categorieOption[],
  continuousEducationToEdit: ContinuousEducationManager | null,
};

const initialState: PlanningListState = {
  isLoading: true,
  error: null,
  continuousEducationOptions: [],
  continuousEducationToEdit: null,
};

const slice = createSlice({
  name: 'continuousEducation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getContinuousEducationOptionsSuccess(state, action){
      state.isLoading = false;
      state.continuousEducationOptions = action.payload;
    },

    getContinuousEducationToEditSuccess(state,action){
      state.isLoading = false;
      state.continuousEducationToEdit = action.payload;
    },

    resetContinuousEducationToEdit(state){
      state.continuousEducationToEdit = null;
    }

  },
});

// Reducer
export default slice.reducer;

export const { resetContinuousEducationToEdit } = slice.actions;

export function getContinuousEducationOptions() {
  return async () => {
    try {
      var continuousEducation = await get('/continuousEducation/all')
      var options: categorieOption[] = [];
      continuousEducation.data.forEach((education: any) =>{
        if(education.hasCategories){
            var subOptions: options[] = [];
            education.continuousEducationCategory.forEach((category: any)=>{
                subOptions.push({value: category._id, label: category.title})
            })
            options.push({value: education._id, label: education.title, subOptions: subOptions})
        }else{
            options.push({value: education._id, label: education.title})
        }
      })
      dispatch(slice.actions.getContinuousEducationOptionsSuccess(options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerContinuousEducation(values: ContinuousEducationManager,categorie: string, subCategorie?: string){
  return async () => {
    try{
      var continuousEducation = await get('/continuousEducation/all')
      var tab: any = null;
      var subTab: any = null;
      var content: any = {};

      content.title = values.title;
      content.description = values.description;
      content.order = values.order;

      if(!isString(values.image)){
        var image64 = await toBase64(values.image)
        var imagem_url = await put('/files/uploadImage',{path: values.image.path, base64: image64})
        content.image = imagem_url.data;
      }


      if(values.link && values.link !== ''){
        content.contentUrl = values.link;
        content.contentType = 'link'
      }else{
        var type = (values.archive.type.includes('video') && 'video') || 
                    (values.archive.type.includes('audio') && 'audio') ||
                    'pdf'
        var document64 = await toBase64(values.archive)
        var document_url = await post('/files/',{file:{path: values.archive.path, base64: document64}})
        content.contentUrl = document_url.data;
        content.contentType = type;
      }

      continuousEducation.data.forEach((education: any) =>{
        if(education._id === categorie){
          if(subCategorie){
             education.continuousEducationCategory.forEach((sub: any) =>{
              if(sub._id === subCategorie){
                subTab = sub;
                tab = education;
              }
             })
          }else{
            tab = education;
          }
        }
      })

      if(subTab){
        subTab.continuousEducationContent.push(content)
        tab.continuousEducationCategory = tab.continuousEducationCategory.filter((category: any) => category._id !== subCategorie)
        tab.continuousEducationCategory.push(subTab)
      }else{
        tab.continuousEducationContent.push(content)
      }

      await put('/continuousEducation/', tab)
    }catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function getContinuousEducationById(id: string) {
  return async () => {
    try {
      var continuousEducation = await get('/continuousEducation/all')
      var educationAux: any = {};
      continuousEducation.data.forEach((education: any) =>{
        if(education.hasCategories){
            education.continuousEducationCategory.forEach((category: any)=>{
                category.continuousEducationContent.forEach((content: any) =>{
                  if(content._id === id){
                    educationAux = {...content,categorie:{value: education._id, label: education.title}, subCategorie:{value: category._id, label: category.title}};
                  }
                })
            })
        }else{
          education.continuousEducationContent.forEach((content: any)=>{
              if(content._id === id){
                educationAux = {...content, categorie:{value: education._id, label: education.title}};
              }
          })
        }
      })
      if(!educationAux._id){
        return 'error'
      }else{
        dispatch(slice.actions.getContinuousEducationToEditSuccess(educationAux));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editContinuousEducation(values: ContinuousEducationManager,id: string,categorie: string, subCategorie?: string){
  return async () => {
    try{
      var continuousEducationResponse = await get('/continuousEducation/all')
      var continuousEducation = continuousEducationResponse.data;
      var continuousEducationToUpdate: any = {};
      var tab: any = null;
      var subTab: any = null;

      continuousEducation.forEach((education: any) =>{
        if(education.hasCategories){
          education.continuousEducationCategory.forEach((category: any) =>{
            category.continuousEducationContent.forEach((content: any, index: number) => {
              if(content._id === id){
                category.continuousEducationContent.splice(index, 1);
                continuousEducationToUpdate = education;
              }
            })
          })
        }else{
          education.continuousEducationContent.forEach((content: any, index: number) => {
            if(content._id === id){
              education.continuousEducationContent.splice(index, 1);
              continuousEducationToUpdate = education;
            }
          })
        }
      })

      await put('/continuousEducation/', continuousEducationToUpdate)

      continuousEducationResponse = await get('/continuousEducation/all')
      continuousEducation = continuousEducationResponse.data;

      var content: any = {};

      content.title = values.title;
      content.description = values.description;
      content.order = values.order;

      if(!isString(values.image)){
        var image64 = await toBase64(values.image)
        var imagem_url = await put('/files/uploadImage',{path: values.image.path, base64: image64})
        content.image = imagem_url.data;
      }else{
        content.image = values.image
      }

      if(values.link && values.link !== ''){
        content.contentUrl = values.link;
        content.contentType = 'link'
      }else{
        if(isString(values.archive)){
          content.contentUrl = values.archive;
          content.contentType = values.contentTypeAux;
        }else{
          var type = (values.archive.type.includes('video') && 'video') || 
                     (values.archive.type.includes('audio') && 'audio') ||
                     'pdf'
          var document64 = await toBase64(values.archive)
          var document_url = await post('/files/',{file:{path: values.archive.path, base64: document64}})
          content.contentUrl = document_url.data;
          content.contentType = type;
        }
      }

      continuousEducation.forEach((education: any) =>{
        if(education._id === categorie){
          if(subCategorie){
             education.continuousEducationCategory.forEach((sub: any) =>{
              if(sub._id === subCategorie){
                subTab = sub;
                tab = education;
              }
             })
          }else{
            tab = education;
          }
        }
      })

      if(subTab){
        subTab.continuousEducationContent.push(content)
        tab.continuousEducationCategory = tab.continuousEducationCategory.filter((category: any) => category._id !== subCategorie)
        tab.continuousEducationCategory.push(subTab)
      }else{
        tab.continuousEducationContent.push(content)
      }

      await put('/continuousEducation/', tab)
    }catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}


const toBase64 = (file: CustomFile) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export function deleteContinuousEducation(id: string){
  return async () =>{
    try{
      var continuousEducationResponse = await get('/continuousEducation/all');
      var tab_id = null;
      var category_id= null;

      continuousEducationResponse.data.forEach((education: any) =>{
        if(education.hasCategories){
          education.continuousEducationCategory.forEach((category: any) =>{
            category.continuousEducationContent.forEach((content: any, index: number) => {
              if(content._id === id){
                tab_id = education._id;
                category_id = category._id;
              }
            })
          })
        }else{
          education.continuousEducationContent.forEach((content: any, index: number) => {
            if(content._id === id){
              tab_id = education._id
            }
          })
        }
      })

      if(category_id){
        await put('/continuousEducation/delete',{tabId: tab_id, categoryId: category_id, contentId: id})
      }else{
        await put('/continuousEducation/delete',{tabId: tab_id, contentId: id})
      }

    }catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}