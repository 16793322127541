import { createSlice } from '@reduxjs/toolkit';
// @types
import { options } from '../../../@types/categories/categories';
import { useDispatch, } from '../../store'
import { get, post, put } from '../api/api'

import { dispatch } from '../../store';

// ----------------------------------------------------------------------

type ageGroupState = {
  isLoading: boolean,
  error: Error | string | null;
  ageGroupOptions: {value: string, label: string, ageGroupGroupId: string}[];
};

const initialState: ageGroupState = {
  isLoading: true,
  error: null,
  ageGroupOptions: []
};

const slice = createSlice({
  name: 'ageGroup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAgeGroupsOptionsSuccess(state, action){
      state.isLoading = false;
      state.ageGroupOptions = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

export function getAgeGroupsOptions() {
  return async () => {
    try {
      var ageGroups = await get('/options/all?options=ageGroups')
      var options: {value: string, label: string, ageGroupGroupId: string}[] = [];
      ageGroups.data.ageGroups.forEach((group: any) =>{
        if(group.status === 'active'){
          options.push({value: group._id, label: group.title, ageGroupGroupId: group.ageGroupGroupId})
        }
      })
      options.sort(function(a, b){
        if(a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
        if(a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
        return 0;
      })
      dispatch(slice.actions.getAgeGroupsOptionsSuccess(options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createOrUpdateAgeGroup(ageGroup: {_id?: string, title?: string, status?: string, ageGroupGroupId?: string}) {
  return async () => {
    try {
      await put('/options/ageGroup', ageGroup)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}