import { createSlice } from '@reduxjs/toolkit';
// @types
import { ActivityManager, SectionManager } from '../../../@types/activity/activity';
//
import { dispatch } from '../../store';
import { _userList } from '../../../_mock';

// ----------------------------------------------------------------------

function objFromArray(array: any[], key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

type UserDetailsState = {
  isLoading: boolean,
  error: Error | string | null;
  activityValues: null | ActivityManager
  sectionsValues: null | SectionManager
  editIndex: null | number,
  planningEditId: null | string,
};

const initialState: UserDetailsState = {
  isLoading: true,
  error: null,
  activityValues: null,
  sectionsValues: null,
  editIndex: null,
  planningEditId: null,
};

const slice = createSlice({
  name: 'activityDetails',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    putActivityValues(state, action){
      state.activityValues = action.payload.values;
      state.sectionsValues = action.payload.sections;
    },

    setEditIndex(state, action){
      state.editIndex = action.payload;
    },

    setPlanningEdtiId(state, action){
      state.planningEditId = action.payload;
    },

    resetValues(state){
      state.activityValues= null
      state.sectionsValues= null
      state.editIndex= null
      state.planningEditId= null
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { putActivityValues, setEditIndex, setPlanningEdtiId, resetValues } = slice.actions;