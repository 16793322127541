import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from '../redux/store';

// ----------------------------------------------------------------------

type PlanningValuesGuardProps = {
  children: ReactNode;
};

export default function PlanningValuesGuard({ children }: PlanningValuesGuardProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { planningValues } = useSelector((state) => state.planningDetails)

  if (!planningValues) {
    enqueueSnackbar('Erro',{variant:'error'});
    return <Navigate to={'/dashboard'} />
  }

  return <>{children}</>;
}