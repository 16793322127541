import { createSlice } from '@reduxjs/toolkit';
import { PlanningManager, PlanningRegisterManager } from '../../../@types/planning/planning';
import { ActivityManager, ActivityRegisterManager, SectionRegisterManager } from '../../../@types/activity/activity';
import { options } from '../../../@types/categories/categories';
import { get, post, put } from '../api/api'
import { isString } from 'lodash'
import { dispatch } from '../../store';
import { CustomFile } from 'src/components/upload';
import { INFANTIL_ID, FUNDAMENTAL_FINAIS_ID, FUNDAMENTAL_INICIAIS_ID, INFANTIL_NAME, FUNDAMENTAL_FINAIS_NAME, FUNDAMENTAL_INICIAIS_NAME } from 'src/config'

type UserDetailsState = {
  isLoading: boolean,
  error: Error | string | null;
  planningValues: PlanningManager | null,
  experiencesValues: any | null,
  activities: ActivityManager[]
};

const initialState: UserDetailsState = {
  isLoading: true,
  error: null,
  planningValues: null,
  experiencesValues: null,
  activities: [],
};

const slice = createSlice({
  name: 'planningDetails',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    savePlanningValues(state, action) {
      state.planningValues = action.payload.values;
      state.experiencesValues = action.payload.experiences;
    },

    addActivity(state, action) {
      state.activities.push(action.payload)
    },

    deleteActivity(state, action) {
      state.activities.splice(action.payload, 1)
    },

    editActivity(state, action) {
      state.activities[action.payload.index] = action.payload.activity;
    },

    resetPlanningValues(state) {
      state.planningValues = null
      state.experiencesValues = null
      state.activities = []
    },

    getPlanningToEditSuccess(state, action) {
      state.isLoading = false;
      state.planningValues = action.payload.planningValues;
      state.experiencesValues = action.payload.experienceFields;
      state.activities = action.payload.activities;
    },

    swapActivities(state, action) {
      state.activities = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { savePlanningValues, addActivity, deleteActivity, editActivity, resetPlanningValues, swapActivities } = slice.actions;

export function getPlanningToEdit(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const planning = await get('/planejamento/byId/' + id)
      if (planning.status === 500) {
        return 'erro'
      } else {
        var planningAux = planning.data;
        var ageGroups: options[] = [];
        planningAux.ageGroups.forEach((ageGroup: any) => {
          ageGroups.push({ value: ageGroup._id, label: ageGroup.title })
        })
        var planningValues = {
          _id: planningAux._id,
          nome: planningAux.nome,
          descricao: planningAux.descricao,
          imagem: planningAux.imagem,
          ageGroups: ageGroups,
          ageGroupGroup: planningAux.ageGroupGroup._id,
          order: planningAux.order
        }
        var experienceFields = await setExperienceFields(planningAux.experienceFields)
        var activities = setActivities(planningAux.atividades)
        dispatch(slice.actions.getPlanningToEditSuccess({ planningValues, experienceFields, activities }));
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
}

const setExperienceFields = async (fields: any[]) => {
  var fieldsAux: any[] = [];
  var map = fields.map(async (field, eindex) => {
    var knowledges: any[] = [];
    var map = field.knowledges.map(async (knowledge: any) => {
      var learnings: any[] = [];
      var allLearnings = await get('/attributes/learning/getAll?knowledge=' + knowledge._id)
      allLearnings.data.forEach((learn: any) => {
        if (knowledge.learnings.filter((l: any) => l._id === learn._id)[0]) {
          learnings.push({ value: learn._id, label: learn.title, selected: true })
        } else {
          learnings.push({ value: learn._id, label: learn.title, selected: false })
        }
      })
      learnings.sort(function (a, b) {
        if (a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
        if (a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
        return 0;
      })
      knowledges.push({ value: knowledge._id, label: knowledge.title, learnings: learnings })
    })
    await Promise.all(map)
    fieldsAux.push(
      {
        experience: field._id,
        title: field.title,
        knowledge: knowledges,
        experience_name: 'Experience_' + eindex,
        knowledge_name: 'Knowledge_' + eindex
      })
  })
  await Promise.all(map)
  return fieldsAux;
}

const setActivities = (activities: any[]) => {
  var activitiesAux: any[] = [];
  activities.forEach((activity) => {
    var pedagogicCategories: any[] = [];
    var sections: any[] = [];

    activity.sections.forEach((section: any) => {
      var fields: any[] = [];
      section.conteudos.forEach((conteudo: any, index: number) => {
        if (conteudo.tipo === 'TAG' || conteudo.tipo === 'BULLET') {
          fields.push({ value: conteudo.conteudos, type: conteudo.tipo, name: section.title + conteudo.tipo + 'Field_' + index })
        } else if (conteudo.tipo === 'TEXTO') {
          fields.push({ value: conteudo.text, type: conteudo.tipo, name: section.title + conteudo.tipo + 'Field_' + index })
        } else if (conteudo.tipo === 'VIMEO_VIDEO') {
          fields.push({ value: conteudo.url, type: conteudo.tipo, name: section.title + conteudo.tipo + 'Field_' + index })
        } else {
          fields.push({ value: conteudo.url, type: 'ARCHIVE', name: section.title + 'ARCHIVEField_' + index, auxType: conteudo.tipo })
        }
      })
      sections.push({ section: section.title, fields: fields })
    })

    activity.pedagogicCategories.forEach((categorie: any) => {
      pedagogicCategories.push({ value: categorie._id, label: categorie.descricao })
    })

    activitiesAux.push(
      {
        _id: activity._id,
        images: activity.images,
        title: activity.title,
        description: activity.description,
        time: activity.estimatedTime ? activity.estimatedTime : '',
        showTime: activity.estimatedTime ? false : true,
        pedagogicCategories,
        sections,
      }
    )
  })
  return activitiesAux;
}

export function createOrUptadePlanning(planningValues: PlanningManager, experiences: any[], activities: ActivityManager[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      var planningAux: PlanningRegisterManager = {} as PlanningRegisterManager;
      planningAux.nome = planningValues.nome;
      planningAux.descricao = planningValues.descricao;
      planningAux.order = planningValues.order;
      planningAux.ageGroupGroup = planningValues.ageGroupGroup;
      planningAux.ageGroups = [];
      planningValues.ageGroups.forEach((ageGroup) => {
        planningAux.ageGroups.push(ageGroup.value)
      })

      planningAux.experienceFields = setExperienceFieldsToRegister(experiences, planningValues.ageGroupGroup)

      planningAux.atividades = await setActivitiesToRegister(activities)

      if (planningValues._id) {
        planningAux._id = planningValues._id;
      }

      if (!isString(planningValues.imagem)) {
        var imagem64 = await toBase64(planningValues.imagem)
        var imagem_url = await put('/files/uploadImage', { path: planningValues.imagem.path, base64: imagem64 })
        planningAux.imagem = imagem_url.data;
      }
      await put('/planejamento/', planningAux)
    } catch (error) {
      throw new Error(error.message);
    }
  }
}


const toBase64 = (file: CustomFile) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const setExperienceFieldsToRegister = (experiences: any[], ageGroupGroup: string) => {
  var ageGroupGroups = ageGroupGroup === INFANTIL_ID ?
    [{ _id: INFANTIL_ID, title: INFANTIL_NAME }] :
    [{ _id: FUNDAMENTAL_FINAIS_ID, title: FUNDAMENTAL_FINAIS_NAME }, { _id: FUNDAMENTAL_INICIAIS_ID, title: FUNDAMENTAL_INICIAIS_NAME }]
  var experiencesAux: any[] = []
  experiences.forEach((experience) => {
    var knowledges: any[] = [];
    var learnings: any[] = [];
    experience.knowledge.forEach((knowledge: any) => {
      knowledge.learnings.forEach((learning: any) => {
        if (learning.selected) {
          learnings.push({
            _id: learning.value,
            title: learning.label,
            knowledge: knowledge.value,
            ageGroupGroups: ageGroupGroups,
          })
        }
      })
      knowledges.push({
        _id: knowledge.value,
        title: knowledge.label,
        experienceField: experience.experience,
        learnings: learnings,
        ageGroupGroups: ageGroupGroups,
      })
    })
    experiencesAux.push({
      _id: experience.experience,
      title: experience.title,
      ageGroupGroups: ageGroupGroups,
      knowledges: knowledges
    })
  })
  return experiencesAux;
}

const setActivitiesToRegister = async (activities: ActivityManager[]) => {
  var activitiesAux: string[] = [];

  for (const activity of activities) {
    var activityAux: ActivityRegisterManager = {} as ActivityRegisterManager;
    if (activity._id) {
      activityAux._id = activity._id;
    }

    if (!activity.showTime) {
      activityAux.estimatedTime = parseInt(activity.time)
    }

    activityAux.title = activity.title;
    activityAux.description = activity.description;
    activityAux.pedagogicCategories = [];
    activityAux.images = [];
    activityAux.sections = [];

    for (const image of activity.images) {
      var imageAux: any = image;

      if (!isString(image)) {
        var imagem64 = await toBase64(image)
        var imagem_url = await put('/files/uploadImage', { path: imageAux.path, base64: imagem64 })
        activityAux.images.push(imagem_url.data)
      } else {
        activityAux.images.push(image)
      }
    }

    for (const category of activity.pedagogicCategories) {
      activityAux.pedagogicCategories.push(category.value)
    }

    for (const section of activity.sections) {
      var sectionAux: SectionRegisterManager = {} as SectionRegisterManager;
      sectionAux.conteudos = [];
      sectionAux.title = section.section;

      var sectionFieldsAux = [...section.fields];
      while (sectionFieldsAux.length > 0) {
        let fieldAux: any = await fieldUpload(sectionFieldsAux[0])
        sectionAux.conteudos.push(fieldAux)
        sectionFieldsAux.splice(0, 1)
      }

      if (sectionAux.conteudos.length > 0) {
        activityAux.sections.push(sectionAux);
      }
    }

    var newActivity = await put('/atividade/', activityAux)
    activitiesAux[activities.indexOf(activity)] = newActivity.data._id;
  }

  return activitiesAux;
}

const fieldUpload = (field: any) => new Promise(async (resolve, reject) => {
  if (field.type === 'TAG' || field.type === 'BULLET') {
    resolve({ tipo: field.type, conteudos: field.value });
  } else if (field.type === 'TEXTO') {
    resolve({ tipo: field.type, text: field.value });
  } else if (field.type === 'VIMEO_VIDEO') {
    resolve({ tipo: field.type, url: field.value });  
  } else {
    if (isString(field.value) && field.auxType) {
      resolve({ tipo: field.auxType, url: field.value })
    } else {
      const type = (field.value.type.includes('video') && 'VIDEO') || (field.value.type.includes('audio') && 'AUDIO') || (field.value.type.includes('image') && 'IMAGEM') || 'DOCUMENTO'
      const document64 = await toBase64(field.value)
      const response = await post('/files/', { file: { path: field.value.path, base64: document64 } })
      resolve({ tipo: type, url: response.data })
    }
  }
});

export function deletePlanning(id: string) {
  return async () => {
    try {
      await put('/planejamento/', { _id: id, status: 'inactive' })
    } catch (error) {
      throw new Error(error.message);
    }
  }
} 